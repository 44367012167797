import { Instance, TextObject, UniqueLocation } from './types';


// function that generates non-colliding locations meaning that if there are two instances with the same location, only one of them will be displayed
const generateNonCollidingLocations = (instances: Instance[]): { start: number; end: number }[] => {
    const uniqueLocations: UniqueLocation[] = [];

    instances.forEach(instance => {
        instance.location.forEach(location => {
            const isColliding = uniqueLocations.some(uniqueLocation => {
                return (
                    (location.start >= uniqueLocation.start && location.end <= uniqueLocation.end) ||
                    (location.start <= uniqueLocation.start && location.end >= uniqueLocation.end)
                );
            });
            if (!isColliding) {
                uniqueLocations.push({ start: location.start, end: location.end, comment: instance.comment });
            }
        });
    });

    uniqueLocations.sort((a, b) => a.start - b.start);

    return uniqueLocations;
};

// funtion that splits the text into objects with the following structure: { text: string, isHighlighted: boolean, comment: string }
function splitTextIntoObjects(text: string, uniqueLocations: UniqueLocation[]): TextObject[] {
    const textObjects: TextObject[] = [];
    let currentIndex = 0;

    uniqueLocations.forEach(location => {
        const start = location.start;
        const end = location.end;
        const comment = location.comment;

        if (currentIndex < start) {
            const beforeText = text.slice(currentIndex, start);
            textObjects.push({ text: beforeText, isHighlighted: false, comment: '' });
        }

        const highlightedText = text.slice(start, end);
        textObjects.push({ text: highlightedText, isHighlighted: true, comment });

        currentIndex = end;
    });

    if (currentIndex < text.length) {
        const remainingText = text.slice(currentIndex);
        textObjects.push({ text: remainingText, isHighlighted: false, comment: '' });
    }

    return textObjects;
}

export { generateNonCollidingLocations, splitTextIntoObjects };