// AboutUsPage.js
import React from 'react';
import './style.scss';

const AboutUsPage = () => {
    return (
        <div id='about-us'>
            <p>Наш проект использует ИИ для проверки текстов на предвзятость и эмоциональное манипулирование (запугивание и разжигание ненависти). </p>
            <p>Оценивать можно любую новость на русском языке, не только в СМИ, но и в блогах. А можно брать новостное издание и давать ему оценку, составляя рейтинг. Мы постарались сбалансировать данные, которые использовали для разработки подхода, чтобы оценивать наличие манипуляций беспристрастно, вне зависимости от политической позиции.</p>
            <p>На главной странице вы можете посмотреть новости из специального выпуска, содержащего известные проправительственные СМИ. Мы используем данные проекта TrueStory для сбора сюжетов.</p>
            <p>Далее мы применяем нейронные сети для первичной оценки текста, определяя для каждого предложения, есть ли в нём эмоциональная манипуляция. Если да, то передаём текст ChatGPT на более подробный анализ. Прототип, который вы видите, был разработан в рамках двухдневнего хакатона Demhack 7. Мы продолжаем работу в рамках акселератора Рефорум. </p>
            <p>Если вам интересно поучаствовать в разработке проекта, вы хотите дать обратную связь или использовать наши модели в своей работе, свяжитесь с <a href = "https://forms.gle/sURzyxfXxskHSWK59">нами</a>.</p>
        </div>
    );
};

export default AboutUsPage;
