import React from 'react';
import { useNavigate } from 'react-router-dom';
import ManipulationGrader from '../grader/ManipulationGrader';
import './teaser.scss';

import formatDateDistance from '../../utils/formatDateDistance';

const Teaser = ({ header, teaser, scale, timedate, id, imgSrc, noLink }) => {
    const navigate = useNavigate();
    const timeAgo = formatDateDistance(timedate);
    return (
        <div className="teaser-root">
            <div className="teaser-content">

                <div className="img-container">
                    <img src={imgSrc} alt="" />
                </div>
                <ManipulationGrader grade={scale * 100} />
                <h5>{header}</h5>
                <p className="teaser">{teaser}</p>
                <div className="bottom">

                    <p className="time">{timeAgo}</p>
                    {!noLink && <button className='details' onClick={() => navigate(`/news/${id}`)}>Подробнее</button>}
                </div>
            </div>
        </div>
    );
};

export default Teaser;
