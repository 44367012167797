

function generateFiltersURL(selectedFilters) {
    let findFields = '';
    let findValues = '';

    if (selectedFilters.source && selectedFilters.source.length > 0) {
        selectedFilters.source.forEach(source => {
            findFields += 'source_slug,';
            findValues += source.slug + ',';
        });
    }

    if (selectedFilters.method && selectedFilters.method.length > 0) {
        selectedFilters.method.forEach(method => {
            findFields += 'manipulation_method,';
            findValues += method.id + ',';
        });
    }

    findFields = findFields.slice(0, -1);
    findValues = findValues.slice(0, -1);
    if(findFields === '' && findValues === '') return '';
    return `&find_fields=${findFields}&find_values=${findValues}`;
}

function getBaseURL() {
    return 'https://textgericht.oplot.team';
}

export { generateFiltersURL, getBaseURL };