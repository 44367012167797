import React from 'react';

interface NotificationBannerProps {
    text: string;
    backgroundColor: string;
}

const NotificationBanner: React.FC<NotificationBannerProps> = ({ text, backgroundColor }) => {
    return (
        <div style={{ backgroundColor }} className="notification-banner">
            {text}
        </div>
    );
};

export default NotificationBanner;