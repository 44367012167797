import { getBaseURL } from "../../api/utils"

const url = `${getBaseURL()}/list/manipulation_methods`

const getMethods = async () => {
    try {
        const response = await fetch(url)
        const data = await response.json()
        return data
    } catch (error) {
        console.error('Error fetching sources', error)
    }
}
export default getMethods;