import React, { useState, useEffect } from 'react';
import { TextPiece, TextProps, UniqueLocation } from './types';

import { generateNonCollidingLocations, splitTextIntoObjects } from './utils.ts';


const Text: React.FC<TextProps> = ({ text, methods, selectedMethods }) => {
    const [formattedText, setFormattedText] = useState<TextPiece[]>([]);
    const [isHovered, setIsHovered] = useState<TextPiece | null>(null);
    useEffect(() => {
        const uniqueLocations: UniqueLocation[] = [];
        methods.forEach(method => {
            if (selectedMethods.some(selectedMethod => selectedMethod.id === method.id)) {
                uniqueLocations.push(...generateNonCollidingLocations(method.instances));
            }
        });

        const textPieces = splitTextIntoObjects(text, uniqueLocations);
        setFormattedText(textPieces);
    }, [text, methods, selectedMethods]);

    return (
        <p className="article">
            {formattedText.map((piece, index) => (
                <React.Fragment key={index}>
                    {piece.isHighlighted ? <span className="highlight" onMouseEnter={() => setIsHovered(piece)} onMouseLeave={() => setIsHovered(null)}>{piece.text}</span> : piece.text}
                    <div style={{ position: 'relative', display: "inline" }}>

                        {piece.comment && isHovered === piece && <span className="comment">{piece.comment}</span>}
                    </div>
                </React.Fragment>
            ))}
        </p>
    );
};


export default Text;

