import React, { useState } from 'react';
import './sortFilter.scss';
import SortIcon from '../../icon/sort.svg';
import FilterIcon from '../../icon/sliders.svg';
import CloseIcon from '../../icon/close.svg';
import Selection from './Selection';

const SortFilterMobile = ({ sortOptions, filters, selectedFilters, selectedSort, setSelectedSort, setSelectedFilter }) => {
    const [sortSelected, setSortSelected] = useState(selectedSort);
    const [filtersSelected, setFiltersSelected] = useState(selectedFilters);
    const [isSortOpen, setIsSortOpen] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const onSortClick = ({ field, order }) => {
        setSortSelected({ field, order });
    };

    const onFilterSelect = (id, list) => {
        setFiltersSelected(prevFilters => ({
            ...prevFilters,
            [id]: list
        }));
    };

    const onSortReset = () => {
        setSortSelected('');
        setSelectedSort('');
        setIsSortOpen(false);
    };

    const onFilterReset = () => {
        setFiltersSelected([{}]);
        setSelectedFilter([{}]);
        setIsFilterOpen(false);
    };

    const onSortAccept = () => {
        setSelectedSort(sortSelected);
        setIsSortOpen(false);
    };

    const onFilterAccept = () => {
        setSelectedFilter(filtersSelected);
        setIsFilterOpen(false);
    };

    const onSortClose = () => {
        onSortReset();
        setIsSortOpen(false);
    };

    const onFilterClose = () => {
        setIsFilterOpen(false);
    };

    return (
        <div className="sort-filter-mobile">
            <div className="sort-filter-buttons">
                <button className="sort" onClick={() => setIsSortOpen(true)}>
                    <img src={SortIcon} alt="" />
                    <p>Упорядочить</p>
                    {selectedSort && <div className="circle"></div>}
                </button>
                <button className="filter" onClick={() => setIsFilterOpen(true)}>
                    <img src={FilterIcon} alt="" />
                    <p>Фильтры</p>
                    {selectedFilters && Object.keys(selectedFilters).length > 1 && <div className="circle"></div>}
                </button>
            </div>

            {isSortOpen && <div className="sort-content">
                <div className="list">
                    {sortOptions.map(({ field, order, name }) => (
                        <button key={field + order} id={field + order} className="sortButton" onClick={() => onSortClick({field, order})}>
                            <p>{name}</p>
                            {sortSelected.field + sortSelected.order === field+order && <p className="selected">•</p>}
                        </button>
                    ))}
                </div>
                <div className="sort-list-control">
                    <div className="change">
                        <button className="reset" onClick={onSortReset}>Сбросить</button>
                        <button className="accept" onClick={onSortAccept}>Применить</button>
                    </div>
                    <button className="cancel" onClick={onSortClose}>
                        <img src={CloseIcon} alt="close icon" />
                    </button>
                </div>
            </div>}
            {isFilterOpen && <div className="filter-content">
                <div className="filter-inputs">
                    {
                        filters&&filters.map((filter) => (
                            <Selection onSelect={onFilterSelect} selected={filtersSelected[filter.id]} key={filter.id} id={filter.id} name={filter.name} options={filter.options} />
                        ))
                    }
                </div>
                <div className="filter-list-control">
                    <div className="change">
                        <button className="reset" onClick={onFilterReset}>Сбросить</button>
                        <button className="accept" onClick={onFilterAccept}>Применить</button>
                    </div>
                    <button className="cancel" onClick={onFilterClose}>
                        <img src={CloseIcon} alt="close icon" />
                    </button>
                </div>
            </div>}
        </div>
    );
};

export default SortFilterMobile;
