// TextAnalysisPage.js
import React, { useState } from 'react';
import sendText from '../../api/sendText';
import Text from '../story/Text.tsx';
import Methods from '../story/Methods.tsx';
import ManipulationGrader from '../../components/grader/ManipulationGrader';

import './styles.scss';
import NotificationBanner from './SourceBanner.tsx';


const TOO_LITTLE = 10;
const TOO_MUCH = 4000;
type ResponseType = {
    id: string;
    manipulation_level: number;
    manipulation_methods: string[];
    preview_text: string;
    scraper_platform: string;
    source: {
        color: string;
        icon: string;
        id: string;
        name: string;
        slug: string;
    };
    text: string;
    url: string;
    references: {
        label: string;
        color: string;
    };
};

const TextAnalysisPage = () => {
    const [text, setText] = useState('');
    const [error, setError] = useState<null | string>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [response, setResponse] = useState<null | ResponseType>(null);
    const [methods, setMethods] = useState([]);

    const handleSubmission = async () => {
        if (text.length < TOO_LITTLE) {
            setError("Слишком короткий текст");
            return;
        }
        if (text.length > TOO_MUCH) {
            setError("Слишком длинный текст");
            return;
        }
        setError(null);
        try {
            setLoading(true);
            const response = await sendText(text);
            console.log(response)
            if (!response.error) {
                setResponse(response);

            } else {
                setError(response.error);
            }
        } catch (error) {
            console.error(error);
            setError(error.message);
        }
        setLoading(false);
    };

    return (
        <div className="analyze-text-root">
            <div className="analyze-text">
                <h3>Анализировать текст</h3>
                <textarea
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Введите публицистический текст на русском языке, длиной от 10 до 4000 слов."
                />
                {error &&
                    <div className="notification">
                        <p>{error}</p>
                    </div>
                }
                <div className="wrapper">
                    <button
                        disabled={loading}
                        onClick={handleSubmission}
                    > {loading ? <p className='loading'>Загрузка...</p>
                        :
                        <p>Анализировать</p>
                        }</button>

                </div>

            </div>


            {response &&
                <div className='article-root' >
                    <ManipulationGrader grade={response.manipulation_level * 100} />
                    {response && response.references &&
                        (
                            <div className="notification">
                                <NotificationBanner text={response.references.label} backgroundColor={response.references.color} />
                            </div>)
                    }
                    <Text text={response.text} methods={response.manipulation_methods} selectedMethods={methods} />
                    {response.manipulation_methods && response.manipulation_methods.length > 0 && (
                        <Methods methods={response.manipulation_methods} selectedMethods={methods} setSelectedMethods={setMethods} />
                    )}
                </div>
            }


        </div>
    );
};

export default TextAnalysisPage;