import { useState, useEffect } from 'react';
import axios from 'axios';
import { generateFiltersURL, getBaseURL } from './utils'
function useGetFeed(page = 1, per = 15, selectedSort = {sortFields: '', sortOrders: ''}, selectedFilters = [{}]) {
    const [feed, setFeed] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [cache, setCache] = useState({});

    const { field: sortFields, order: sortOrders } = selectedSort;

    async function fetchData(noCache = false) {
        try {
            if (!loading) {
                setLoading(true);
                const newData = [];
                for (let i = 1; i <= (noCache ? 1: page); i++) {
                    if (cache[i] && !noCache) {
                        newData.push(...cache[i]);
                    } else {
                        const response =
                            await axios.get(
                                `${getBaseURL()}/feed?page=${i}&per=${per}&sort_orders=${sortOrders}&sort_fields=${sortFields}${generateFiltersURL(selectedFilters)}`
                            );
                        const pageData = response.data;
                        setCache(prevCache => ({
                            ...prevCache,
                            [i]: pageData
                        }));
                        newData.push(...pageData);
                    }
                }

                setFeed(newData);
                setLoading(false);
            }
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    }    

    useEffect(() => {
        // Reset cache when selectedSort changes
        setFeed([]);
        setCache({});
        fetchData(true);

    }, [sortFields, sortOrders, selectedFilters]);

    useEffect(() => {
        fetchData();

    }, [page, per]);

    return [feed, loading, error];
}

export default useGetFeed;
