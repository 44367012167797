import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getBaseURL } from './utils';

function useGetStory(storyId, selectedSort = { sortFields: '', sortOrders: '' }) {
    const [story, setStory] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const source = axios.CancelToken.source();

        async function fetchData() {
            try {
                setLoading(true);
                const response = await axios.get(`${getBaseURL()}/story?slug=${storyId}`, {
                    cancelToken: source.token
                });
                setStory(response.data);
                setLoading(false);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setError(error);
                    setLoading(false);
                }
            }
        }

        fetchData();

        // Cleanup function to cancel ongoing request when component unmounts
        return () => {
            source.cancel('Request canceled by cleanup');
        };
    }, [storyId]);

    return { story, loading, error };
}

export default useGetStory;