// LandingPage.js
import React, { useEffect, useState } from 'react';
import SortFilterMobile from '../../components/sortFilter/SortFilterMobile';
import './story.scss';
import Teaser from '../../components/teaser/Teaser';
import Article from './Article.tsx';


import useGetStory from '../../api/getStory';
import storyData from '../../data/story.json';
import SortFilterDesktop from '../../components/sortFilter/SortFilterDesktop';
import Spinner from '../../components/loading/Loading';
import Notification from '../../components/notification/Notification';
import ShareButtons from '../../components/shareWindow/ShareButtons';
import { useParams } from 'react-router-dom';

const sortOptions = [
    {
        field: 'date',
        order: 'desc',
        name: 'Сначала новые'
    },
    {
        field: 'date',
        order: 'asc',
        name: 'Сначала старые'
    },
    {
        field: 'manipulation_level',
        order: 'desc',
        name: 'Высокий уровень эмоциональной манипуляции'
    },
    {
        field: 'manipulation_level',
        order: 'asc',
        name: 'Низкий уровень эмоциональной манипуляции'
    }
];
const filters_default = [
    {
        id: 'method',
        name: 'Метод манипуляции',
        options: [
            {
                id: 'emotions',
                name: 'Эмоции'
            },
            {
                id: 'scale',
                name: 'Масштаб'
            }
        ]
    }
];

const Story = () => {

    const [selectedSort, setSelectedSort] = useState('');
    const [selectedFilters, setSelectedFilters] = useState({});
    const [texts, setTexts] = useState([]);
    const [filters, setFilters] = useState(filters_default);

    const params = useParams();

    const { story, loading, error } = useGetStory(params['newsId'], selectedSort);
        
    const filterTexts = (story, selectedSort, selectedFilters) => {
        const rawTexts = story.texts;
        const sortedTexts = rawTexts.sort((a, b) => {
            if (selectedSort.field === 'date') {
                return selectedSort.order === 'asc' ? new Date(a.date) - new Date(b.date) : new Date(b.date) - new Date(a.date);
            } 
            if (selectedSort.field === 'manipulation_level') {
                return selectedSort.order === 'asc' ? a.manipulation_level - b.manipulation_level : b.manipulation_level - a.manipulation_level;
            }
        });

        // Filter the sorted texts based on the selected filters
        const filteredTexts = sortedTexts.filter(text => {
            return Object.keys(selectedFilters).every(filter => {
                if (filter === 'method') {
                    // Filter by manipulation methods
                    return selectedFilters[filter].every(method => text.manipulation_methods.some(textMethod => textMethod.id === method.id))
                }
                // Include all other filters
                return true;
            });
        });

        return filteredTexts;
    };


    // 
    useEffect(() => {
        if (story) {
            const filteredTexts = filterTexts(story, selectedSort, selectedFilters);
            setTexts(filteredTexts);
        }
    }, [story, selectedSort, selectedFilters]);

    useEffect(() => {
        if (story) {
            const methods = story.texts.map(text => text.manipulation_methods).flat();
            const uniqueMethods = methods.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
            setFilters(
                filters_default.map(filter => {
                    return {
                        ...filter,
                        options: uniqueMethods.map(method => {
                            return {
                                id: method.id,
                                name: method.method
                            };
                        })
                    };
                })
            );
        }
    }, [story]);
    return (
        <div className='feed-root'>
            <div className="feed-settings">
                <SortFilterDesktop
                    sortOptions={sortOptions}
                    filters={filters}
                    selectedSort={selectedSort}
                    selectedFilters={selectedFilters}
                    setSelectedSort={setSelectedSort}
                    setSelectedFilter={setSelectedFilters} />
            </div>
            <div className="feed-content">
                <SortFilterMobile
                    sortOptions={sortOptions}
                    filters={filters}
                    selectedSort={selectedSort}
                    selectedFilters={selectedFilters}
                    setSelectedSort={setSelectedSort}
                    setSelectedFilter={setSelectedFilters} />
                <div className="align-center">
                    {error && <Notification text={error} type='error' />}
                    {loading && <Spinner />}
                </div>
                {!loading && !!story &&
                    <>
                        <Teaser
                            noLink
                            key={story.id}
                            imgSrc={story.img}
                            header={story.title} teaser={story.summary} scale={story.manipulation_level} timedate={story.date} id={storyData.slug} />
                        {texts.map(item => (
                            <Article
                                key={item.id}
                                source={item.source}
                                timedate={item.date}
                                scale={item.manipulation_level}
                                text={item.text}
                                url={item.url}
                                methods={item.manipulation_methods}
                            />
                        ))}
                    </>
                }
            </div>

        </div>
    );
};

export default Story;
