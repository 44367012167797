import React, { useState } from 'react';
import Selection from './Selection';
import SortIcon from '../../icon/sort.svg'
import FilterIcon from '../../icon/sliders.svg'
import './sort-filter-desktop.scss'

const SortFilterDesktop = ({ sortOptions, filters, selectedFilters, selectedSort, setSelectedSort, setSelectedFilter }) => {
    const [filt, setFilt] = useState(selectedFilters);
    const [sort, setSort] = useState(selectedSort);
    const [isSortOpen, setIsSortOpen] = useState(false);
    const onSortSelect = ({ field, order }) => {
        setSort({field, order})
    }
    const onFilterSelect = (id, list) => {
        setFilt(prevFilters => ({
            ...prevFilters,
            [id]: list
        }));
    };
    const onSortAccept = () => {
        setSelectedSort(sort);
    };

    const onFilterAccept = () => {
        setSelectedFilter(filt);
    };

    const onSortReset = () => {
        setSort('')
        setSelectedSort('')
    }
    const onFilterReset = () => {
        setFilt([{}])
        setSelectedFilter([{}])
    }
    return (
        <div className='sort-filter-desktop'>
            <div className="sort">
                <div className="header" onClick={() => setIsSortOpen(true)}>
                    <img src={SortIcon} alt="" />
                    <p>Упорядочить</p>
                    {selectedSort && <div className="circle"></div>}
                </div>
                <div className="settings">
                    <div className="list">
                        {sortOptions.map(({ field, order, name }) => (
                            <button key={field+order} id={field+order} className="sortButton" onClick={() => onSortSelect({field, order})}>
                                <p>{name}</p>
                                {sort.field + sort.order === field+order && <p className="selected">•</p>}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="control">
                    <button className="reset" onClick={onSortReset}>Сбросить</button>
                    <button className="accept" onClick={onSortAccept}>Применить</button>
                </div>
            </div>
            <div className="filter">
                <div className="header" onClick={() => setIsFilterOpen(true)}>
                    <img src={FilterIcon} alt="" />
                    <p>Фильтры</p>
                    {selectedFilters && Object.keys(selectedFilters).length > 1 && <div className="circle"></div>}
                </div>
                <div className="settings">
                    <div className="filter-inputs">
                        {
                            filters&&filters.map((filter) => (
                                <Selection onSelect={onFilterSelect} selected={filt[filter.id]} key={filter.id} id={filter.id} name={filter.name} options={filter.options} />
                            ))
                        }
                    </div>
                </div>
                <div className="control">
                    <button className="reset" onClick={onFilterReset}>Сбросить</button>
                    <button className="accept" onClick={onFilterAccept}>Принять</button>
                </div>
            </div>
        </div>
    );
}

export default SortFilterDesktop;
