import React from 'react';
import './grader.scss'; // Import your SCSS file

const GradeBar = ({ grade }) => {
    // Ensure the grade is within the range of 0 to 100
    const normalizedGrade =  parseInt(Math.min(Math.max(grade, 0), 100));
    const getColor = (grade) => {
        const startColor = [46, 125, 50]; // RGB for #2e7d32
        const middleColor = [249, 168, 37]; // RGB for #f9a825
        const endColor = [198, 40, 40]; // RGB for #c62828

        let color;
        if (grade <= 20) {
            color = startColor
        } else if (grade <= 66) {
            color = middleColor
        } else {
            color = endColor
        }
        return `rgb(${color.join(', ')})`;
    };

    // Check if grade is not a number
    if (isNaN(normalizedGrade)) {
        return (
            <div className='grade-bar-root'>
                <div className="grade-bar">
                    <div className="filled-bar" style={{ flex: `0 0 0%`, background: 'grey' }}></div>
                    <div className="empty-bar" style={{ borderColor: 'grey' }}></div>
                </div>
                <div className="grade-label" style={{ color: 'grey' }}>Анализ в процессе</div>
            </div>
        );
    }

    return (
        <div className='grade-bar-root'>
            <div className="grade-bar">
                <div className="filled-bar" style={{ flex: `0 0 ${normalizedGrade}%`, background: getColor(normalizedGrade) }}></div>
                <div className="empty-bar" style={{ borderColor: getColor(normalizedGrade) }}></div>
            </div>
            <div className="grade-label" style={{ color: getColor(normalizedGrade) }}>{`${normalizedGrade}%`} эмоциональной манипуляции</div>
        </div>
    );
};

export default GradeBar;
