import React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    style: {
        // top: "20px",
    },
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    },
    disabled: {
        style: {
            opacity: 0.5,
            pointerEvents: 'none'
        }
    }
};

const Selection = ({ id, name, options, onSelect, selected }) => {

    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        const selectedOptions = options.filter(option => value.includes(option.name));
        onSelect(id, selectedOptions);
    };
    return (

        <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id={'label-' + id}>{name}</InputLabel>
            <Select
                labelId={'label-' + id}
                id={id}
                multiple
                value={selected&&selected.map(option => option.name) || []}
                onChange={handleChange}
                disabled={options.length === 0}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={() => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected&&selected.map((option) => (
                            <Chip key={option.id} label={option.name} />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
                sx={{
                    '&.Mui-disabled': {
                        opacity: 0.5,
                        pointerEvents: 'none',
                    }
                }}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option.id}
                        value={option.name}
                    >
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default Selection;
