// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Feed from './pages/feed/Feed';
import Story from './pages/story/Story';
import AboutUsPage from './pages/about/AboutUsPage';
import TextAnalysisPage from './pages/analyse/TextAnalysisPage.tsx';
import Navigation from './components/nav/Navigation';

import './App.scss';

export const ROUTES = {
    about: '/about',
    analyse: '/analyse',
    root: '/'
};

const App = () => {
    return (
        <Router>
            <Navigation />
            <div className="content">
                <div className="flex">
                    <Routes>
                        <Route path={ROUTES.root} exact element={<Feed/>} />
                        <Route path="/news/:newsId" element={<Story />} />
                        <Route path={ROUTES.about} element={<AboutUsPage/>} />
                        <Route path={ROUTES.analyse} element={<TextAnalysisPage/>} />
                    </Routes>
                </div>
            </div>
        </Router>
    );
};

export default App;
