import React from 'react';
import './notification.scss'
const Notification = ({text, type}) => {
    return (
        <div className={`notification-root`}>
            <div className={`notification-${type}`}>
                {text}
            </div>
        </div>
    );
}

export default Notification;
