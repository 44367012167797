import { useState, useEffect } from "react";
import axios from "axios";
import { getBaseURL } from "./utils";

const sendText = async (text) => {
    try {
        const response = await axios.post(`${getBaseURL()}/analyse_text`, {
            text: text
        });
        if (response.status !== 200) {
            throw new Error("Ошибка, попробуйте ещё " + response.status);
        }
        return response.data;
    } catch (error) {
        throw error;
    }
}

export default sendText;
