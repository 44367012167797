// LandingPage.js
import React, { useEffect, useState } from 'react';
import SortFilterMobile from '../../components/sortFilter/SortFilterMobile';
import './feed.scss';
import Teaser from '../../components/teaser/Teaser';
import useGetFeed from '../../api/getFeed'
import ExtendIcon from '../../icon/extend.svg'

import SortFilterDesktop from '../../components/sortFilter/SortFilterDesktop';
import Loading from '../../components/loading/Loading';
import Notification from '../../components/notification/Notification';
import getSources from '../../components/sortFilter/getSources';
import getMethods from '../../components/sortFilter/getMethods';

const sortOptions = [
    {
        field: 'date',
        order: 'desc',
        name: 'Сначала новые'
    },
    {
        field: 'date',
        order: 'asc',
        name: 'Сначала старые'
    },
    {
        field: 'manipulation_level',
        order: 'desc',
        name: 'Высокий уровень эмоциональной манипуляции'
    },
    {
        field: 'manipulation_level',
        order: 'asc',
        name: 'Низкий уровень эмоциональной манипуляции'
    }
];

const filters = [
    {
        id: 'source',
        name: 'Источник',
        options: [
        ]
    },
    {
        id: 'method',
        name: 'Метод эмоциональной манипуляции',
        options: [
            {
                id: 'emotions',
                name: 'Эмоции'
            },
            {
                id: 'scale',
                name: 'Масштаб'
            }
        ]
    }
];

const Feed = () => {

    const [selectedSort, setSelectedSort] = useState('');
    const [selectedFilters, setSelectedFilters] = useState([{}]);
    const [itemsAmount, setItemsAmount] = useState(1);

    const [sources, setSources] = useState(filters[0]);
    const [methods, setMethods] = useState(filters[1]);
    const [feed, loading, error] = useGetFeed(itemsAmount, 5, selectedSort, selectedFilters);


    useEffect(() => {
        const fetchData = async () => {
            const newSource = await getSources();
            if (newSource) setSources({...filters[0], options: newSource});
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const newMethods = await getMethods();
            if (newMethods) setMethods({...filters[1], options: newMethods});
        }
        fetchData();
    }, []);


    useEffect(() => {
        setItemsAmount(1)
    }
    , [selectedSort])

    const onClickMore = () => {
        setItemsAmount((am)=> am+1)
    }

    return (
        <div className='feed-root'>
            <div className="feed-settings">
                <SortFilterDesktop
                    sortOptions={sortOptions}
                    filters={[sources, methods]}
                    selectedSort={selectedSort}
                    selectedFilters={selectedFilters}
                    setSelectedSort={setSelectedSort}
                    setSelectedFilter={setSelectedFilters} />
            </div>
            <div className="feed-content">
                <SortFilterMobile
                    sortOptions={sortOptions}
                    filters={[sources, methods]}
                    selectedSort={selectedSort}
                    selectedFilters={selectedFilters}
                    setSelectedSort={setSelectedSort}
                    setSelectedFilter={setSelectedFilters} />
                {error&&<Notification text={error} type='error' />}
                {feed&&feed.map((item, index) => (
                    <Teaser
                        key={index}
                        imgSrc={item.img}
                        header={item.title} teaser={item.summary} scale={item.manipulation_level} timedate={item.date} id={item.slug} />
                ))}
                
                
                <div className="extend-container">
                    {loading ? <Loading /> :
                        <button onClick={onClickMore} className='more'>
                            <img src={ExtendIcon} alt="more" />
                        </button>
                    }
                </div>
            </div>
                
        </div>
    );
};

export default Feed;
