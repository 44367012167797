import React from 'react';

const Source = ({ colour, name, icon }) => {
    return (
        <div className="source">
            <img src={icon} className="icon" />
            <p className="sourceName" style={{ "color": colour}}>{name}</p>
        </div>
    );
}

export default Source;
