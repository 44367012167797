import React from 'react';
import Modal from 'react-modal';

import TelegramSVG from '../../icon/telegram.svg'
import CloseIcon from '../../icon/close.svg'
import WhatsAppSVG from '../../icon/whatsapp.svg'
import VKSVG from '../../icon/vk.svg'
import LinkSVG from '../../icon/link.png'

import './share.scss'
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '8px',
        padding: '0',
        maxWidth: '500px',
        width:'100%'
        
    },
};

function ShareButtons({ isOpen, setIsOpen }) {
    const closeModal = () => {
        setIsOpen(false);
    };
    const handleTelegramClick = () => {
        const shareUrl = 'https://t.me/share/url?url=' + encodeURIComponent(window.location.href);
        window.open(shareUrl, '_blank');
    };

    const handleWhatsAppClick = () => {
        const shareUrl = 'whatsapp://send?text=' + encodeURIComponent(window.location.href);
        window.open(shareUrl, '_blank');
    };

    const handleVKClick = () => {
        const shareUrl = 'https://vk.com/share.php?url=' + encodeURIComponent(window.location.href);
        window.open(shareUrl, '_blank');
    };

    const handleCopyLinkClick = () => {
        navigator.clipboard.writeText(window.location.href);
        alert('Ссылка скопирована');
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Share"
            style={customStyles}
        >
            <div className='modal-content'>
                <div className="title">
                    <p className="share">Поделиться</p>
                    <button className="close" onClick={closeModal}>
                        <img src={CloseIcon} alt="close icon" />
                    </button>
                </div>
                <div className="buttons">

                    <button onClick={handleTelegramClick}>
                        <img src={TelegramSVG} alt='Telegram' />
                    </button>
                    <button onClick={handleWhatsAppClick}>
                        <img src={WhatsAppSVG} alt='WhatsApp' />
                    </button>
                    <button onClick={handleVKClick}>
                        <img src={VKSVG} alt='VK' />
                    </button>
                </div>
                <button className='copy' onClick={handleCopyLinkClick}>
                    <p>Скопировать ссылку</p>
                    <img src={LinkSVG} alt='Link' />
                </button>
            </div>
        </Modal>
    );
}

export default ShareButtons;