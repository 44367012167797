// NewsPage.js
import React, { useState } from 'react';
import ManipulationGrader from '../../components/grader/ManipulationGrader'

import './article.scss'

import formatDateDistance from '../../utils/formatDateDistance';
import Source from './Source';
import Methods from './Methods.tsx';
import Text from './Text.tsx';
import { SelectedMethods } from './types';

const Article = ({ source, timedate, scale, text, url, methods }) => {
    const [isTimeHovered, setIsTimeHovered] = useState(false);
    const [selectedMethods, setSelectedMethods] = useState<SelectedMethods>([]);
    let timeAgo;
    if (timedate) {
        timeAgo = formatDateDistance(timedate);
    }

    return (
        <div className="article-root">
            <div className="article-content">
                <div className="source-time">
                    <Source {...source} />
                    {timeAgo && (
                        <div className='timedate'>
                            <p className="time" onMouseEnter={() => setIsTimeHovered(true)} onMouseLeave={() => setIsTimeHovered(false)}>
                                {timeAgo}
                            </p>
                            {isTimeHovered && (
                                <p className="full-date">{new Date(timedate).toLocaleString()}</p>
                            )}
                        </div>
                    )}
                </div>
                <ManipulationGrader grade={scale * 100} />
                <Text text={text} methods={methods} selectedMethods={selectedMethods} />
                <div className="bottom">
                    <a href={url} className="redirect">Источник</a>
                </div>
                {methods && methods.length > 0 && (
                    <Methods methods={methods} selectedMethods={selectedMethods} setSelectedMethods={setSelectedMethods} />
                )
                }
            </div>
        </div>
    );
};

export default Article;
