import { Radio } from '@mui/material';
import React from 'react';

import { MethodsProps } from './types';

const Methods = (
    {
        methods,
        selectedMethods,
        setSelectedMethods
    }: MethodsProps
) => {
    const [isHoveredList, setIsHoveredList] = React.useState([{}]);
    // check if methods has instance key in it
    const hasInstances = methods.some((method) => !!method.instances)
    return (
        <div className="methods-control">
            {hasInstances &&
                <>
                    <h2>Подсветить приёмы</h2>
                    <p>Обратите внимание: пока что мы выделяем только эмоциональную манипуляцию. Мы не оцениваем непредвзятость и достоверность фактической информации.</p>
                    <div className="button-list">

                        {methods.map((method, index) => {
                            const hasInstances = method.instances.some((instance) => instance.location.length > 0)
                            return (

                                <button key={index} onClick={() => {
                                    setSelectedMethods([{ id: method.id }]);
                                }}>
                                    {/* only if method.instances has location */}
                                    {hasInstances
                                        ? <Radio
                                            checked={selectedMethods.some((selectedMethod) => selectedMethod.id === method.id)}
                                            onChange={() => { }}
                                        /> :
                                        <div className='placeholder' />}
                                    <p>
                                        {method.method}
                                    </p>
                                </button>
                            )
                        }
                        )}
                    </div>
                </>
            }
        </div>
    );
}

export default Methods;
