import React, { useState } from 'react';

import Logo from '../../icon/logo.svg';
import './styles.scss';
import './burger.css';
import { ROUTES } from '../../App';
import { useNavigate } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import ShareIcon from '../../icon/share.svg'
import MenuI from '../../icon/menu.svg';
import ShareButtons from '../shareWindow/ShareButtons';

const MenuIcon = () => {
    return (
        <img src={MenuI} />
    );
};

const Navigation = () => {
    const navigate = useNavigate();

    const [isShareOpen, setIsShareOpen] = useState(false)
    const onClickShare = () => {
        setIsShareOpen(prev => !prev)
    }

    return (
        <nav>
            <div className="nav-content">
                <div className="nav-mobile">
                    <h1>Насквозь</h1>
                    <Menu right customBurgerIcon={<MenuIcon/>} >
                        <button id="home" className="menu-item" onClick={() => { navigate(ROUTES.root); }}>лента новостей</button>
                        <button id="about" className="menu-item" onClick={() => { navigate(ROUTES.analyse); }}>анализировать текст</button>
                        <button id="contact" className="menu-item" onClick={() => { navigate(ROUTES.about); }}>о проекте</button>
                    </Menu>
                </div>
                <div className="nav-desktop">
                    <div className="nav-links">
                        <button id="home" className="menu-item" onClick={() => { navigate(ROUTES.root); }}>лента новостей</button>
                        <button id="about" className="menu-item" onClick={() => { navigate(ROUTES.analyse); }}>анализировать текст</button>
                        <button id="contact" className="menu-item" onClick={() => { navigate(ROUTES.about); }}>о проекте</button>
                    </div>
                    <div className="nav-header">
                        <h1>Насквозь</h1>
                        <ShareButtons isOpen={isShareOpen} setIsOpen={setIsShareOpen} />
                        <button className="share" onClick={onClickShare}>Поделиться
                            <img src={ShareIcon} alt="share" />
                        </button>
                    </div>

                </div>
            </div>

        </nav>
    );
};

export default Navigation;
