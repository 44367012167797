import { getBaseURL } from "../../api/utils";

const url = `${getBaseURL()}/list/sources`

const getSources = async () => {
    try {
        const response = await fetch(url)
        const data = await response.json()
        return data
    } catch (error) {
        console.error('Error fetching sources', error)
    }
}
export default getSources;